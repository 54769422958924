import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, Paragraph, Column, Figure } from "@blocks/page/Section"
import { Table } from "@blocks/page/Table"
import { Tags } from "@blocks/page/Tags"
import { ContentFooter } from "@projects/ContentFooter"
import css from "@css/projects/page/p-content.module.styl"

export default function Info() {
	const q = useStaticQuery(graphql`
		query {
            main01 : file(relativePath: { eq: "pageInfo__img__hero@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			message : file(relativePath: { eq: "pageVision__img__message@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			img01 : file(relativePath: { eq: "pageInfo__img__01@2x.jpg" }) { childImageSharp {
                    fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid_withWebp }
            } }
			img02 : file(relativePath: { eq: "pageInfo__img__02@2x.jpg" }) { childImageSharp {
				fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid_withWebp }
			} }
			img03 : file(relativePath: { eq: "pageInfo__img__03@2x.jpg" }) { childImageSharp {
				fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid_withWebp }
			} }
			img04 : file(relativePath: { eq: "pageInfo__img__04@2x.jpg" }) { childImageSharp {
				fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid_withWebp }
			} }
			img05 : file(relativePath: { eq: "pageInfo__img__05@2x.jpg" }) { childImageSharp {
				fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid_withWebp }
			} }
        }
    `)
	return (
		<>
			<Seo subtitle={'INFORMATION'}/>
			<Hero
				image={q.main01}
				label='企業情報'
				title1='Information'
				title2=''
				links={[
					{
						label: '営業所',
						scroll: '#office'
					},{
						label: '保有車両',
						scroll: '#vehicle'
					},{
						label: '保有資格',
						scroll: '#qualification'
					},
				]}
			/>
			<div className={css.container}>
				<div className={css.inner}>
					<BreadcrumbList title="企業情報" />
					<Section id="office">
						<Title title1='Office' title2='営業所' />
						<Table title='大泉建設株式会社'
							data={[
								[
									`代表者`,
									`代表取締役　小泉　國雄`
								], [
									`所在地`,
									`<p>
									<strong>本社</strong><br>
									青森県上北郡六ヶ所村大字倉内字笹崎1157<br>
									TEL:0175-74-2468 FAX:0175-74-2368
									</p>
									<p>
									<strong>再処理事業所</strong><br>
									青森県上北郡六ヶ所村大字尾駮字弥栄平1-5<br>
									TEL: 0175-71-1125 FAX: 0175-71-1126
									</p>
									`
								], [
									`建設業許可`,
									`土木、建築、とび、土工、電気、舗装、しゅんせつ、塗装、水道、機械設備、造園`
								], [
									`業務`,
									`交通誘導警備業務、六ヶ所村指定排水設備工事、土地・建物の販売斡旋、注文住宅・宅地販売、借家・アパート斡旋、損害保険代理店`
								], [
									`従業員数`,
									`230名`
								]
							]}
						/>
						<Figure caption="※大泉建設株式会社、大泉運輸株式会社、ファウンテン株式会社">
							<FluidImage data={q.img01}/>
						</Figure>
						<Table title='大泉運輸株式会社'
							data={[
								[
									`代表者`,
									`代表取締役　小泉　國雄`
								], [
									`所在地`,
									`<p>
									<strong>本社</strong><br>
									青森県上北郡六ヶ所村大字倉内字笹崎1157<br>
									TEL:0175-74-2488 FAX:0175-74-2368
									</p>
									<p>
									<strong>八戸（営）</strong><br>
									青森県八戸市西白山台５丁目13-4<br>
									TEL:0178-32-6325 FAX:0178-32-6326
									</p>
									`
								], [
									`建設業許可`,
									`土木`
								],[
									`業務`,
									`一般区域貨物自動車運送業、貸切バス、産業廃棄物収集運搬業、産業廃棄物処分業、砂利採取業、原子力関連施設のメンテナンス業務`
								], [
									`従業員数`,
									`54名`
								]
							]}
						/>
						<Figure caption="※大泉運輸株式会社 八戸営業所">
							<FluidImage data={q.img02}/>
						</Figure>
						<Table title='ファウンテン株式会社'
							data={[
								[
									`代表者`,
									`代表取締役　小泉　國雄`
								], [
									`所在地`,
									`<strong>本社</strong><br>
									青森県上北郡六ヶ所村大字倉内字笹崎1157<br>
									TEL:0175-74-3309 FAX:0175-74-2368`
								], [
									`建設業許可`,
									`土木、建築`
								], [
									`業務`,
									`六ヶ所村立南こども園指定管理運営、新車・中古車販売、自動車部品及びタイヤ販売、燃料販売、ビル清掃業、リース業`
								], [
									`従業員数`,
									`16名`
								], [
									`URL`,
									'<a href="https://daisen-g.co.jp/minami/" target="_blank">六ヶ所村立南こども園</a>'
								]
							]}
						/>
						<Figure caption="※六ヶ所村立南こども園">
							<FluidImage data={q.img04}/>
						</Figure>
						<Table title='株式会社大生'
							data={[
								[
									`代表者`,
									`代表取締役 滝沢 敏也`
								], [
									`所在地`,
									`<strong>三沢本社</strong><br>
									青森県三沢市松園町3丁目2-11<br>
									TEL:0176-52-5647 FAX:0176-52-5649<br>
									おいらせ事業所 青森県上北郡おいらせ町緑ヶ丘7丁目50-2006
									`
								],[
									`設立`,
									`2009年(平成21年)10月19日`
								],[
									`資本金`,
									`1,000万円`
								],[
									`建設業許可`,
									`とび・土工、土木、大工、鉄筋、機械器具設置、解体、石、鋼構造物、舗装、 しゅんせつ、水道施設`
								], [
									`業務`,
									`総合建設業`
								], [
									`従業員数`,
									`82名`
								], [
									`URL`,
									'<a href="https://taisei-misawa.co.jp/">https://taisei-misawa.co.jp</a>'
								]
							]}
						/>
						<Figure caption="※株式会社大生 おいらせ事業所">
							<FluidImage data={q.img05}/>
						</Figure>
					</Section>
					<Section id="vehicle">
						<Title title1='Vehicle' title2='保有車両' />
						<Tags data={
							[
								"トラクター(トレーラーヘッド) ",
								"重トレーラー",
								"トレーラー",
								"ユニック車 10t",
								"ユニック車 4t",

								"平ボディー 10t",
								"平ボディー 4t",-
								"平ボディー 2t",

								"ダンプ 10t",
								"ダンプ 4t",
								"ダンプ 3t",
								"ダンプ 2t",
								"ウイング",
								"ボックス",

								"大型観光バス",
								"中型観光バス",
								"マイクロバス",
								"タイヤショベル",
								"ブルドーザー",
								"フォークリフト",
								"Wピック",
								"タンクローリー",
								"ホイールクレーン",
								"グレーダ",
								"ロータリー車",
								"バックホー",
								"不整地運搬車",
								"振動ローラー"
							]
						}/>
					</Section>
					<Section id="qualification">
						<Title title1='Qualification' title2='保有資格' />
						<Tags data={
							[
								"普通自動車免許",
								"中型自動車免許(8t)",
								"大型自動車免許",
								"大型特殊自動車免許",
								"けん引免許",
								"中型自動車第二種免許",
								"大型自動車第二種免許",
								"クレーン運転士",
								"移動式クレーン運転士",
								"第1種放射線取扱主任者",
								"第2種放射線取扱主任者",
								"第一種衛生管理者(旧衛生管理者)",
								"公害防止管理者(大気関係第二種)",
								"公害防止管理者(大気関係第四種)",
								"公害防止管理者(水質関係第二種)",
								"第二種電気工事士",
								"消防設備士(乙種6類)",
								"高圧ガス製造保安責任者",
								"二級ボイラー技士",

								"非破壊試験技術者(PTレベル1)",
								"非破壊試験技術者(PTレベル2)",
								"あと施エアンカー(第1種施工士)",
								"あと施工アンカー(第2種施工士)",
								"一級建築士",
								"二級建築士",
								"1級建築施工管理技士",
								"2級建築施工管理技士",
								"1級土木施工管理技士",
								"2級土木施工管理技士",
								"1級機械施工",
								"2級(1種)建築機械施工技士",
								"2級(2種)建築機械施工技士",
								"2級(3種)建築機械施工技士",
								"2級(4種)建築機械施工技士",
								"2級(5種)建築機械施工技士",
								"2級造園施工管理技士",
								"2級管工事施工管理技士",
								"1級舖装施工管理技術者",

								"解体工事施工技士",
								"測量士補",
								"二級塗装技能士",
								"建設業経理士(2級)",
								"監理技術者",
								"危険物取扱者(丙種)",
								"危険物取扱者(乙種第1類)",
								"危険物取扱者(乙種第2類)",
								"危険物取扱者(乙種第3類)",
								"危険物取扱者(乙種第4類)",
								"危険物取扱者(乙種第5類)",
								"危険物取扱者(乙種第6類)",
								"溶接作業指導者",
								"溶接管理技術者(2級)",
								"ステンレス鋼溶接技能者",
								"炭素鋼溶接技能者",
							]
						} />
					</Section>
				</div>
			</div>
			<ContentFooter
				banner={{
					img: q.main01,
					label: 'CSR活動',
					title: '大泉グループの取り組み',
					to: '/csr/',
				}}
			/>
		</>
	)
}
