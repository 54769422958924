import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/page/p-tags.module.styl"

function Tags({title,data})
{
    return(
        <div className={css.container}>
            { data && data.map((row, i)=>{
                return(
                    <span className={css.item} key={i}>{row}</span>
                )
            })}
        </div>
    )
}
export { Tags }
